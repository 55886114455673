.section-title {
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 3rem;
  }
  .section-title h4 {
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  .section-title div {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: var(--primaryColor);
  }
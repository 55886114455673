.grid-item {
    border: none;
    z-index: 50;
}

.dashboard-grid-item:hover {
    border: 2px solid grey;
}

.dashboard-grid-item:hover > .item-hover-button{
    visibility: visible;
}

.dashboard-grid-item > span {
    visibility: hidden;
}

.dashboard-grid-item:hover > span {
    visibility: visible;
}

.react-grid-item.react-grid-placeholder:hover ~ div {
    border: 2px solid grey;
}

.react-grid-item.resizing {
    border: 2px solid grey;
}

.react-grid-item.resizing > span {
    visibility: visible;
}

.dashboard-grid-item.showBorders {
    border: 2px solid grey;
    pointer-events: none;
}

.dashboard-grid-item.hideBorders {
    pointer-events: all;
}

.grid-item.react-draggable-dragging {
    z-index: 100;
    border: 2px solid grey;
    pointer-events: none;
}

.item-hover-button {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: var(--mainWhite);
    font-size: 20px;
    background-color: #d32d27;
    visibility: hidden;
}

.item-hover-button:hover {
    cursor: pointer;
}

.grid-item-contents-container {
    height: 100%;
    width: 100%;
    z-index: 20;
}

.item-settings-button {
    position: absolute;
    top: 2px;
    right: 2px;
}

.item-delete-button {
    position: absolute;
    top: 34px;
    right: 2px;
}

#delete-icon {
    color: white;
}
#full-form-container {
    background-color: var(--mainWhite);
    border-radius: 8px;
    position: relative;
    bottom: 320px;
    /* bottom: 8px; */
    min-height: 90vh;
    /* border: 2px solid gold; */
    display: grid; 
    grid-template-columns: auto;
    grid-template-rows: auto auto;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

#textbox1 {
    grid-column-start: 1;
    grid-column-end: 3;
}

#textbox2 {
    grid-column-start: 1;
    grid-column-end: 3;
}
.dashboard-section {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    background-color: var(--primaryBlue);
}

.dashboard-section h4 {
    font-size: 14px;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
}

.dashboard-section .line {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: var(--primaryColor);
    margin-bottom: 4rem;
}

.dashboard-container {
    /* border: 2px solid gold; */
    background-color: var(--lightDarkGrey);
    height: 320px;
    padding: 0rem 2rem 2rem 2rem;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.header-container {
    display: flex;
    align-items: flex-start;
}

#dashboard-header {
    width: 0;
    visibility: hidden;
}

#forms-list {
    display: flex;
}

.plus-button {
    border-radius: .2rem;
    font-size: 80px;
    padding: 0px;
    height: 60px;
    width: 60px;
    background-color: var(--lightDarkGrey);
}

.plus-button > p {
    height: 60px;
    width: 60px;
    position: relative;
    bottom: 28px;
    left: 2px;
    color: var(--mainGrey);
}

.plus-button:hover {
    background-color: var(--mainDarkGrey);
}

#form-toggle-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
}

#form-toggle-button {
    width: 44px;
    display: flex;
    align-items: center;
}

.thumbnailItem {
    z-index: 100;
    height: 100%;
    width: 100%;
    border-radius: 4px;
}

.thumbnailItem:hover {
    cursor: pointer;
};
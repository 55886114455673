@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primaryColor: #d4a744;
  --primaryColorLight: #e9b74c;
  --primaryColorDark: #b98a25;
  --primaryBlue: #6b7b96;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #f2f3f7;
  --mainDarkGrey: #afbfbf;
  --lightDarkGrey: #cecece;
  --darkGrey: #202529;
  --mainTransition: all 0.0s linear;
  --secondTransition: all .07s linear;
  --mainSpacing: .0625em;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

html {
  overflow-x: hidden;
  background-color: #222;
  background-color: var(--mainBlack);
}

/* html.show {
  overflow: hidden;
} */

body {
  color: #222;
  color: var(--mainBlack);
  background: #222;
  background: var(--mainBlack);
  font-family: 'Open Sans';
  line-height: 1.4;
  overflow-x: hidden;
  overflow-y: hidden;
}

body.show {
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}

#app-container {
  background-color: #f2f3f7;
  background-color: var(--mainGrey);
  -webkit-transition: all 0.35s cubic-bezier(0.46, 0.01 , 0.32, 1);
  transition: all 0.35s cubic-bezier(0.46, 0.01 , 0.32, 1);
  background-color: #6b7b96;
  background-color: var(--primaryBlue);
}

#app-container.show {
  -webkit-transform: translateX(400px);
          transform: translateX(400px);
  overflow: visible;
}

#app-container.hide {
  -webkit-transform: translateX(0vw, 0, 0);
          transform: translateX(0vw, 0, 0);
}

#full-form-container {
  -webkit-transition: all 0.35s cubic-bezier(0.46, 0.01 , 0.32, 1);
  transition: all 0.35s cubic-bezier(0.46, 0.01 , 0.32, 1);
}

#full-form-container.show {
  -webkit-transform: translateY(312px);
          transform: translateY(312px);
}

#full-form-container.hide {
  -webkit-transform: translateY(0vh, 0, 0);
          transform: translateY(0vh, 0, 0);
}

h1 {
  font-weight: 700;
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: .8rem;
  line-height: 1.25;
  margin-bottom: 1.25em;
  font-weight: normal;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1rem;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.btn {
  display: inline-block;
  font-weight: 600;
  box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1)!important;
  padding: 1.25rem 2rem;
  font-family: 'Varela Round';
  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: .15rem;
  border: 0;
  line-height: 1.5;
  border-radius: .25rem;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  text-decoration: none;
}

.btn:hover {
  cursor: pointer;
}

.btn-primary {
  border-radius: 30px;
  color: #fff;
  color: var(--mainWhite);
  background: #d4a744;
  background: var(--primaryColor);
}
.btn-primary:hover {
  background-color: #e9b74c;
  background-color: var(--primaryColorLight);
}

.btn-secondary {
  background-color: #f2f3f7;
  background-color: var(--mainGrey);
}

.btn-secondary:hover {
  background-color: #cecece;
  background-color: var(--lightDarkGrey);
}

.btn:focus {
  outline: none;
}

/* start containers */
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-wrapper {
  /* border: 2px solid red; */
  width: 100vw;
  max-width: 1000px;
  text-align: center;
}
/* end containers */

@media (min-width: 576px) {
  .container {
      width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
/* end container */

/* start link */
.link {
  text-decoration: none;
}
/* end link */
.defaultHero {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  padding: 15rem 0;
  /* background: url("../../images/gradient.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
};

.banner {
    display: inline-block;
    background: transparent;
    color: var(--mainWhite);
    padding: 2rem 1rem;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
    border-radius: 3px;
    /* border: 3px solid white; */
    height: 42vh;
    width: 60vw;
    }
    .banner h1 {
        font-size: 2.5rem;
        line-height: 2.5rem;
        letter-spacing: 0.8rem;
        background: -webkit-linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1));
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 400;
        margin-bottom: 0px;
        margin-bottom: 1.5rem;
    }
    .banner h2 {
        max-width: 20rem;
        font-size: 1rem;
        margin-bottom: 1.5rem !important;
    }
    .banner p {
    font-size: 1.2rem;
    margin-bottom: 3rem;
    }
    
    .banner-subtitle{
        color: rgba(255,255,255,1);
        margin: .5rem auto 1.5rem auto;
    }
    
    .banner .social {
        margin-top: 1rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
    }
    
    .icon-button {
        text-align: center;
        height: 3rem;
        width: 3rem;
        background: rgba(255,255,255,.1);
        border-radius: 100%;
        line-height: 3rem;
        color: rgba(255,255,255,.3);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px .5rem;
    }
    
    .icon-button:hover {
      color: rgba(255, 255, 255, 0.5);
    }
    @media screen and (max-width: 575px) {
        .banner {
            position: relative;
        }
    }

    @media screen and (min-width: 576px) and (max-width: 991px) {
    .banner {
        padding: 2rem 3rem;
        position: relative;
    }
    .banner h1 {
        /* font-size: 4rem; */
        /* line-height: 4rem; */
    }
    }
    @media screen and (min-width: 992px) {
    .banner {
        padding: 2rem 6rem;
    }
    .banner h1 {
        /* font-size: 6.5rem; */
        /* line-height: 6.5rem; */
        letter-spacing: 0.8rem;
    }
    .banner h2 {
        max-width: 30rem;
        font-size: 1.25rem;
    }
    }
.featured-section {
    display: flex;
    justify-content: center;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item:hover {
    cursor: pointer;
}

.featured-container {
    padding: 2rem, 0rem;
}

.featured-items-center {
    /* border: 2px solid green; */
    display: grid;
    max-width: 100vw;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: minmax(auto, 350px);
    grid-auto-columns: minmax(200px, auto);
}

.featured-items-center > .item {
    /* border: 2px solid red; */
    max-width: 100%;
}

.featured-items-center > .item > .img-container {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    text-align: center;
}

.featured-items-center > .item > .img-container > img {
    max-height: 80%;
    max-width: 80%;
    margin-bottom: 1.5rem;
    -webkit-transition: var(--secondTransition);
    transition: var(--secondTransition);
}

.featured-items-center > .item > .img-container > img:hover {
    opacity: .8;
}

.featured-items-center > .item:nth-child(1) {
    grid-column: 1/8;
    grid-row: 1/3;
}
.featured-items-center > .item:nth-child(2) {
    grid-column: 8/11;
    max-width: 80%;
}
.featured-items-center > .item:nth-child(3) {
    grid-column: 8/11;
    max-width: 80%;
    justify-self: stretch;
}

.featured-items-center > .item:nth-child(2n+4) {
    grid-column: 1/6;
    margin-bottom: 2rem;
    position: relative;
    bottom: 3.5rem;
}
.featured-items-center > .item:nth-child(2n+5) {
    grid-column: 6/11;
    margin-bottom: 2rem;
    position: relative;
    bottom: 3.5rem;
}

.featured-section h6 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: #565656;
}

@media (max-width: 590px) {
    
    .featured-items-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .featured-items-center > .item:nth-child(1n) {
        max-width: 100%;
    }

    .featured-items-center > .item:nth-child(2n+4) {
        position: static;
    }
    .featured-items-center > .item:nth-child(2n+5) {
        position: static;
    }
  }
.loading {
    text-transform: capitalize;
    text-align: center;
    margin-top: 3rem;
  }
.section-title {
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 3rem;
  }
  .section-title h4 {
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  .section-title div {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: var(--primaryColor);
  }
.intro-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 2rem 4rem 2rem;
}

.intro-text-container {
    max-width: 700px;
    text-align: center;
    margin-bottom: 4rem;
}

.intro-section p {
    font-size: 17px;
    color: #565656
}

.intro-feature {
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 2rem;
    margin-right: 2rem;
}

.intro-feature img {
    max-width: 480px;
    justify-self: center;
}

.intro-feature-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}

.intro-feature-text h4 {
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
}

.intro-feature .btn {
    width: 114.88;
    margin-top: 2rem;
    padding: .75rem 1.5rem;
    font-size: 50%;
}

@media (max-width: 654px) {
    .intro-feature {
        display: block;
    }


    .intro-feature img {
        max-width: 80vw;
        margin: 0 2rem 0 2rem;
    }

    .intro-feature-text {
        align-items: center;
        text-align: center;
    }
}
.footer-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.footer-section div {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: var(--primaryColor);
  }

.footer-section p {
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-size: 10px;
    color: var(--mainWhite);
}

.footer-section h4 {
    font-weight: 400;
    margin-bottom: 4rem;
}
.dashboard-section {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    background-color: var(--primaryBlue);
}

.dashboard-section h4 {
    font-size: 14px;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
}

.dashboard-section .line {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: var(--primaryColor);
    margin-bottom: 4rem;
}

.dashboard-container {
    /* border: 2px solid gold; */
    background-color: var(--lightDarkGrey);
    height: 320px;
    padding: 0rem 2rem 2rem 2rem;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.header-container {
    display: flex;
    align-items: flex-start;
}

#dashboard-header {
    width: 0;
    visibility: hidden;
}

#forms-list {
    display: flex;
}

.plus-button {
    border-radius: .2rem;
    font-size: 80px;
    padding: 0px;
    height: 60px;
    width: 60px;
    background-color: var(--lightDarkGrey);
}

.plus-button > p {
    height: 60px;
    width: 60px;
    position: relative;
    bottom: 28px;
    left: 2px;
    color: var(--mainGrey);
}

.plus-button:hover {
    background-color: var(--mainDarkGrey);
}

#form-toggle-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
}

#form-toggle-button {
    width: 44px;
    display: flex;
    align-items: center;
}

.thumbnailItem {
    z-index: 100;
    height: 100%;
    width: 100%;
    border-radius: 4px;
}

.thumbnailItem:hover {
    cursor: pointer;
};
#formThumbnailBox {
    height: 10rem;
    width: 8rem;
    background-color: var(--mainWhite);
    border-radius: 4px;
    margin-right: 8px;
    margin-top: 2rem;
};
#full-form-container {
    background-color: var(--mainWhite);
    border-radius: 8px;
    position: relative;
    bottom: 320px;
    /* bottom: 8px; */
    min-height: 90vh;
    /* border: 2px solid gold; */
    display: grid; 
    grid-template-columns: auto;
    grid-template-rows: auto auto;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

#textbox1 {
    grid-column-start: 1;
    grid-column-end: 3;
}

#textbox2 {
    grid-column-start: 1;
    grid-column-end: 3;
}
.grid-item {
    border: none;
    z-index: 50;
}

.dashboard-grid-item:hover {
    border: 2px solid grey;
}

.dashboard-grid-item:hover > .item-hover-button{
    visibility: visible;
}

.dashboard-grid-item > span {
    visibility: hidden;
}

.dashboard-grid-item:hover > span {
    visibility: visible;
}

.react-grid-item.react-grid-placeholder:hover ~ div {
    border: 2px solid grey;
}

.react-grid-item.resizing {
    border: 2px solid grey;
}

.react-grid-item.resizing > span {
    visibility: visible;
}

.dashboard-grid-item.showBorders {
    border: 2px solid grey;
    pointer-events: none;
}

.dashboard-grid-item.hideBorders {
    pointer-events: all;
}

.grid-item.react-draggable-dragging {
    z-index: 100;
    border: 2px solid grey;
    pointer-events: none;
}

.item-hover-button {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: var(--mainWhite);
    font-size: 20px;
    background-color: #d32d27;
    visibility: hidden;
}

.item-hover-button:hover {
    cursor: pointer;
}

.grid-item-contents-container {
    height: 100%;
    width: 100%;
    z-index: 20;
}

.item-settings-button {
    position: absolute;
    top: 2px;
    right: 2px;
}

.item-delete-button {
    position: absolute;
    top: 34px;
    right: 2px;
}

#delete-icon {
    color: white;
}
.item-page {
    padding-top: 100px;
    display: flex;
    justify-content: center;
}

.item-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-columns: minmax(200px, auto);
}

.item-img-area {
    /* border: 2px solid green; */
    padding: 2rem;
}

.item-grid img {
    width: 560px;
    max-width: 50vw;
}

.item-text-area {
    /* border: 2px solid gold; */
    height: 800px;
    padding: 6rem 2rem;
}

.item-text-area p {
    font-size: 17px;
    color: #565656
}

.item-page h4 {
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
}

.item-page .line {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    margin-top: 1rem;
    background: var(--primaryColor);
    margin-bottom: 2rem;
  }

.item-page h5 {
    color: var(--primaryColor);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.add-to-cart {
    /* border: 2px solid darkred; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plus-minus-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.minus-one {
    /* border: 2px solid gold; */
    width: 2rem;
    height: 2rem;
    color: var(--primaryColor);
}

.plus-one {
    /* border: 2px solid gold; */
    width: 2rem;
    height: 2rem;
    color: var(--primaryColor);
}

.plus-minus-button:hover {
    cursor: pointer;
    color: var(--primaryColorLight)
}

.num-display {
    /* border: 2px solid green; */
    height: 2.5rem;
    width: 3.75rem;
    margin: 0rem .75rem;
}

.num-box {
    height: 100%;
    width: 100%;
    font-size: 18px;
    text-align: center;
}

.add-button {
    border: 1px solid var(--primaryColor);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    height: 2.5rem;
    width: 80%;
}

.add-button:hover {
    cursor: pointer;
}

.description-area {
    margin-top: 2rem;
    /* border: 2px solid gold; */
    width: 100%;
}

.error {
    text-align: center;
    text-transform: uppercase;
    margin: 2rem 0;
    margin-top: 8rem;
    color: (var(--darkGrey), 0.2);
  }

@media (max-width: 590px) {
    .item-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .item-grid img {
        max-width: 80vw;
    }
  }
.account-wrapper {
    height: 100vh;
}

.account-section {
    padding-top: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-section h4 {
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
}


.account-section .line {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: var(--primaryColor);
    margin-bottom: 3rem;
}

.account-section form {
    max-width: 300px;
}

.account-section form input {
    border: 1px solid var(--lightDarkGrey);
    width: 100%;
    height: 3rem;
    margin-bottom: .5rem;
    border-radius: 3px;
    box-shadow: 0 .05rem .05rem 0 rgba(0,0,0,.15);
    -webkit-box-shadow: 0 .05rem .05rem 0 rgba(0,0,0,.15);
    font-size: 18px;
    padding-left: .5rem;
}

.account-section form button {
    width: 100%;
    height: 3rem;
    border-radius: 3px;
    color: white;
    font-size: 20px;
    background-color: var(--primaryColor);
    text-transform: capitalize;
}

.account-section form button:hover {
    cursor: pointer;
    background-color: var(--primaryColorLight);
}

.account-section .container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-section a {
    margin-top: 1rem;
    color: grey;
}

.account-section p {
    color: grey;
    font-size: 12px;
    margin-bottom: 1rem;
}

.invalid {
    border: 1px solid red !important;
}

.invalid-text {
    visibility: hidden;
    display: none;
}

.invalid-text.visible {
    visibility: visible !important;
    display: block;
    color: red;
}

.confirm-info {
    margin-bottom: 2rem !important;
}
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 0.75rem 2rem;
    background: transparent;
    z-index: 1;
  }

  .nav-white {
    color: black;
    /* background-color: var(--primaryBlue); */
  }

  .nav-header {
    display: flex;
    justify-content: space-between;
  }
  .nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  .nav-icon {
    font-size: 1.5rem;
    color: var(--mainWhite);
  }
  .nav-icon.nav-white {
    color: var(--mainBlack);
  }
  .nav-links {
    height: 0;
    overflow: hidden;
    -webkit-transition: var(--mainTransition);
    transition: var(--mainTransition);
    list-style-type: none;
    /* background-color: var(--mainWhite); */
  } 
  .nav-links a {
    font-family: 'Open Sans';
    display: block;
    text-decoration: none;
    padding: 1rem 0;
    color: var(--mainWhite);
    -webkit-transition: var(--mainTransition);
    transition: var(--mainTransition);
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: var(--mainSpacing);
  }
  .nav-links a:hover {
    cursor: pointer;
    color: 30%;
    color: var(--mainWhite, 30%);
  }
  .nav-links.nav-white a {
    color: var(--mainWhite);
  }
  .nav-links.nav-white a:hover {
    color: var(--mainWhite);
  }
  .show-nav {
    height: auto;
  }
  .logo-div {
    /* width: 250px; */
    height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-text {
    font-family: 'Open Sans';
    color: var(--mainWhite);
    text-decoration: none;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: var(--mainSpacing);
  }
  .logo-text.nav-white {
    color: var(--mainWhite);
  }
  
  .navbar.nav-scrolled {
    background-color: var(--mainGrey);
  }
  
  .logo-text.nav-scrolled {
    color: var(--darkGrey);
  }
  
  .nav-icon.nav-scrolled {
    color: var(--darkGrey);
  }
  
  .nav-links.nav-scrolled a {
    color: var(--darkGrey);
    background-color: var(--mainGrey);
  }
  
  .nav-links.nav-scrolled a:hover {
    cursor: pointer;
  }

  #account-link {
    font-size: 20px;
  }


  .account-menu {
    z-index: 30;
    height: 12rem;
    width: 14rem;
    margin: 0rem;
    background-color: var(--mainGrey);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1rem;
    text-align: center;
  }

  .account-menu .btn {
    /* height: 2rem; */
    width: 100%;
    color: var(--primaryColor);
  }

  .account-menu .featured-btn {
    background-color: var(--primaryColor);
    color: var(--mainWhite);
  }
  
  .account-container-container {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100vw;
    padding: 0rem 2rem;
    /* border: 2px solid gold; */
  }

  #cart-icon {
    font-size: 20px;
  }
  
  @media screen and (max-width: 399px) {
    .nav-links.show-nav {
      width: 100vw;
      position: relative;
      right: 32px;
    }

    .account-container-container {
      z-index: 30;
      padding: 0px;
    }

    .account-menu {
      width: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    .nav-btn {
      display: none;
    }
    .nav-center {
      width: 100%;
      max-width: 1170px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .account-container {
      max-width: 1170px;
      margin: 0 auto;
      /* border: 2px solid red; */
      display: flex;
      justify-content: flex-end;
    }
    .nav-links {
      height: auto;
      display: flex;
      margin-left: 4rem;
      background-color: transparent;
    }
    .nav-links a {
      margin: 0 1rem;
      padding: 0.5rem 0;
    }
  }
.about-section {
    height: 80vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--mainWhite);
}

.about-section h4 {
    padding-top: 8rem;
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
}

.about-section .line {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: var(--primaryColor);
    margin-bottom: 4rem;
}

.about-section p {
font-size: 17px;
color: #565656
}
.fullForm {
    width: 100vw;
    height: 100vh;
    background-color: var(--mainWhite);
}
#flyout-menu {
  width: 400px;
  height: 100vh;
  color: var(--mainWhite);
  background-color: var(--mainBlack);
  position: fixed;
  top: 0;
  left: -400px;
  -webkit-transition: all 0.35s cubic-bezier(0.46, 0.01 , 0.32, 1);
  transition: all 0.35s cubic-bezier(0.46, 0.01 , 0.32, 1);
  z-index: 1000;
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:center;
  /* border: 2px solid darkgreen; */
}
 
#flyout-menu.show {
  -webkit-transform: translateX(400px);
          transform: translateX(400px);
}
 
#flyout-menu.hide {
  -webkit-transform: translateX(0vw, 0, 0);
          transform: translateX(0vw, 0, 0);
  overflow: hidden;
}

#close-button {
  color: var(--mainWhite);
  position: absolute;
  height: 1.6rem;
  width: 1.6rem;
  top: 25px;
  right: 30px;
}

#close-button:hover {
  cursor: pointer;
}

#menu-background {
  background-color: transparent;
  /* background-color: rgba(0,0,0, 0.3); */
  z-index: 900;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

#menu-background.show {
    visibility: visible;
}

#menu-background.hide {
    visibility: hidden;
}

#flyout-menu h4 {
  font-size: 1rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 30px;
}

#flyout-menu h3 {
  font-size: 1.5rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 30px;
}

#flyout-menu h5 {
  font-size: 1rem;
  letter-spacing: var(--mainSpacing);
  text-transform: uppercase;
}

#flyout-menu .outer-line {
  width: 21rem;
  height: 1px;
  margin: 0 auto;
  background: var(--primaryColor);
}

#flyout-menu .line {
  width: 21rem;
  height: 1px;
  margin: 0 auto;
  background: var(--primaryColor);
}

#toolbox-items-area {
  flex: 2 1;
  overflow: scroll;
  -ms-overflow-style: none;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#toolbox-items-area::-webkit-scrollbar {
  display: none;
}


#subtotal-area {
  display: flex;
  justify-content: space-between;
  padding-top: 15px
}

.disclaimer {
  color: var(--lightDarkGrey);
  display: flex;
  justify-content: center;
}

.checkout-button {
  border: 1px solid var(--primaryColor);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  height: 2.5rem;
  width: 21rem;
}

.checkout-arrow {
  font-size: 1.5rem;
}

.element-btn {
  color: var(--mainBlack);
}

@media screen and (max-width: 399px) {
  #flyout-menu {
    width: 100vw;
    height: -webkit-fill-available;
  }

  #close-button {
    right: 15px;
  }
}
.cart-item {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.cart-details {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
}

.cart-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 4.5rem;
    width: 100%;
    margin-bottom: .5rem;
}

.item-name {
    grid-column: 1 / span 2;
    justify-self: center;
    width: 100%;
    text-align: center;
    align-self: center;
    justify-self: flex-start;
    margin-bottom: 0rem;
}

.item-price {
    justify-self: end;
    align-self: center;
}

.item-img {
    height: 4.5rem;
    width: 4.5rem;
    min-height: 4.5rem;
    min-width: 4.5rem;
}

.plus-minus-area {
    display: flex;
    padding-left: 1rem;
    align-self: center;
}

.plus-minus {
    color: var(--mainWhite);
    border: 1px solid var(--primaryColor);
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plus-minus:hover {
    cursor: pointer;
    background-color: var(--primaryColor);
}

.amount-area {
    height: 1.25rem;
    width: 2rem; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--primaryColor);
    border-bottom: 1px solid var(--primaryColor);
}



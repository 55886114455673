.account-wrapper {
    height: 100vh;
}

.account-section {
    padding-top: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-section h4 {
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
}


.account-section .line {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: var(--primaryColor);
    margin-bottom: 3rem;
}

.account-section form {
    max-width: 300px;
}

.account-section form input {
    border: 1px solid var(--lightDarkGrey);
    width: 100%;
    height: 3rem;
    margin-bottom: .5rem;
    border-radius: 3px;
    box-shadow: 0 .05rem .05rem 0 rgba(0,0,0,.15);
    -webkit-box-shadow: 0 .05rem .05rem 0 rgba(0,0,0,.15);
    font-size: 18px;
    padding-left: .5rem;
}

.account-section form button {
    width: 100%;
    height: 3rem;
    border-radius: 3px;
    color: white;
    font-size: 20px;
    background-color: var(--primaryColor);
    text-transform: capitalize;
}

.account-section form button:hover {
    cursor: pointer;
    background-color: var(--primaryColorLight);
}

.account-section .container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-section a {
    margin-top: 1rem;
    color: grey;
}

.account-section p {
    color: grey;
    font-size: 12px;
    margin-bottom: 1rem;
}

.invalid {
    border: 1px solid red !important;
}

.invalid-text {
    visibility: hidden;
    display: none;
}

.invalid-text.visible {
    visibility: visible !important;
    display: block;
    color: red;
}

.confirm-info {
    margin-bottom: 2rem !important;
}
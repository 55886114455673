.featured-section {
    display: flex;
    justify-content: center;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item:hover {
    cursor: pointer;
}

.featured-container {
    padding: 2rem, 0rem;
}

.featured-items-center {
    /* border: 2px solid green; */
    display: grid;
    max-width: 100vw;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: minmax(auto, 350px);
    grid-auto-columns: minmax(200px, auto);
}

.featured-items-center > .item {
    /* border: 2px solid red; */
    max-width: 100%;
}

.featured-items-center > .item > .img-container {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    text-align: center;
}

.featured-items-center > .item > .img-container > img {
    max-height: 80%;
    max-width: 80%;
    margin-bottom: 1.5rem;
    transition: var(--secondTransition);
}

.featured-items-center > .item > .img-container > img:hover {
    opacity: .8;
}

.featured-items-center > .item:nth-child(1) {
    grid-column: 1/8;
    grid-row: 1/3;
}
.featured-items-center > .item:nth-child(2) {
    grid-column: 8/11;
    max-width: 80%;
}
.featured-items-center > .item:nth-child(3) {
    grid-column: 8/11;
    max-width: 80%;
    justify-self: stretch;
}

.featured-items-center > .item:nth-child(2n+4) {
    grid-column: 1/6;
    margin-bottom: 2rem;
    position: relative;
    bottom: 3.5rem;
}
.featured-items-center > .item:nth-child(2n+5) {
    grid-column: 6/11;
    margin-bottom: 2rem;
    position: relative;
    bottom: 3.5rem;
}

.featured-section h6 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: #565656;
}

@media (max-width: 590px) {
    
    .featured-items-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .featured-items-center > .item:nth-child(1n) {
        max-width: 100%;
    }

    .featured-items-center > .item:nth-child(2n+4) {
        position: static;
    }
    .featured-items-center > .item:nth-child(2n+5) {
        position: static;
    }
  }
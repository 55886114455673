#flyout-menu {
  width: 400px;
  height: 100vh;
  color: var(--mainWhite);
  background-color: var(--mainBlack);
  position: fixed;
  top: 0;
  left: -400px;
  transition: all 0.35s cubic-bezier(0.46, 0.01 , 0.32, 1);
  z-index: 1000;
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:center;
  /* border: 2px solid darkgreen; */
}
 
#flyout-menu.show {
  transform: translateX(400px);
}
 
#flyout-menu.hide {
  transform: translateX(0vw, 0, 0);
  overflow: hidden;
}

#close-button {
  color: var(--mainWhite);
  position: absolute;
  height: 1.6rem;
  width: 1.6rem;
  top: 25px;
  right: 30px;
}

#close-button:hover {
  cursor: pointer;
}

#menu-background {
  background-color: transparent;
  /* background-color: rgba(0,0,0, 0.3); */
  z-index: 900;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

#menu-background.show {
    visibility: visible;
}

#menu-background.hide {
    visibility: hidden;
}

#flyout-menu h4 {
  font-size: 1rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 30px;
}

#flyout-menu h3 {
  font-size: 1.5rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 30px;
}

#flyout-menu h5 {
  font-size: 1rem;
  letter-spacing: var(--mainSpacing);
  text-transform: uppercase;
}

#flyout-menu .outer-line {
  width: 21rem;
  height: 1px;
  margin: 0 auto;
  background: var(--primaryColor);
}

#flyout-menu .line {
  width: 21rem;
  height: 1px;
  margin: 0 auto;
  background: var(--primaryColor);
}

#toolbox-items-area {
  flex: 2;
  overflow: scroll;
  -ms-overflow-style: none;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#toolbox-items-area::-webkit-scrollbar {
  display: none;
}


#subtotal-area {
  display: flex;
  justify-content: space-between;
  padding-top: 15px
}

.disclaimer {
  color: var(--lightDarkGrey);
  display: flex;
  justify-content: center;
}

.checkout-button {
  border: 1px solid var(--primaryColor);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  height: 2.5rem;
  width: 21rem;
}

.checkout-arrow {
  font-size: 1.5rem;
}

.element-btn {
  color: var(--mainBlack);
}

@media screen and (max-width: 399px) {
  #flyout-menu {
    width: 100vw;
    height: -webkit-fill-available;
  }

  #close-button {
    right: 15px;
  }
}